/*this css is for the card relevant classes/items: CardBack, (not graph), stat-box*/

.card-footer{
	//color:red;
	//background-color:green;
	font-size:0.60rem;
	bottom:5;
	margin:0;
	
}

/*<div className = "card-footer"> * This data is based off the last 5 games played </div> */

.stat-disclaimer{
	font-size:0.4rem;
	color:grey;/*#454545;*/
	margin:0;
	padding:0;
	margin-left: -18px;
	
}

.disclaimer{
	bottom: -0.5%;
	left:0px;/*stat titles are centered, except fr strikeouts*/
	//justify-content:center;
	
	
}

.cardTitle{
	
	//height:10%;
	height:auto;
	padding:3%;
	justify-content:center;
	//width:80%;
	font-size:0.7em;
	
	
}


.cardLabel{
	
	letter-spacing:1px;
	font-size:0.8rem;
	//width:90%;/*before LOL*/
	width:100%;
	//margin:auto;
	//justify-content:center;
	
}

.prop-title{
	
	bottom: 19%;
	
	font-size: 0.62em;
	
}

.back-stat-title{
	margin:auto;
	
}

.front-stat-title{
	left:0px;/*stat titles are centered, except fr strikeouts*/
	//justify-content:center;
	
	
}

.ss-title{
	//background-color:#66FF99;
	//background-color:#ADD8E6;
	background-color:darkblue;
	color: var(--yellow);
	letter-spacing: 0.15em;
	
	text-decoration: underline ;
	font-size: 1.2em;
	
	height:10%;
	width:100%;
	position:relative;
	//border-style:solid;
	//border-width:3px;
	//border-color:black;
	display:table-row;
	

	
}
.ss-stat{
	
	//font-family:Times New Roman;
	//color:darkblue;
	
	
	//background-color:grey;
	//color:dark-grey;
	//background-color:white;
	height:20%;
	width:80%;
	position:relative;
	//border-style:solid;
	//border-width:3px;
	//border-color:black;
	display:table-row;
	//font-size:1.6em;
	//font-size:1.15rem;
	
	font-size:0.85rem;
	margin-top:20%;
	//margin:auto;
	bottom:5px;/*half cup appearance*/
	color: white;
	//text-decoration: underline;
	
	
	
}





/*DATA ON PlayerCARD*/
.player-info{
	background-color:purple;
	
}

.team-name{
	width: 100%;
	display:flex;
	//margin:0;
}

.team-pos{
	width: 100%;
	display:flex;
	
}
/*DATA ON PlayerCARD*/

/*div card class*/

.quick-stats{
	position:relative;
	
	//width:50px;
	//height:30px;
	//background-image: url(/src/icons/baseball-card.jpg);
	//background-size: contain;
	background-size: cover;
	z-index : 0;
	
	margin:10px;
	//margin-bottom:15px;/*could be a solution but need to make cards stay same size*/
	/*margin is for spae between cards*/

}

/*card frames/containers */

.card-container{
	position:relative;
	background-color:var(--blue);
	width:150px;
	height:170px;
	min-width:15em;
	min-height:20em;
	
}

.card-inner{
  position: relative;
  //width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
	
}

.inner-card-frame{


font-family: LegionFont; /*Shrikhand*/

z-index:-1;	
//margin:5px;
margin-top:5%;
//margin-left:14%;
margin-left:5%;


//background-image: url(/src/icons/mantle.jpg);
//background-image: url(/src/icons/jordan.png);

//background-image: url(/src/icons/basketball-player-outline.jpg);

	background-size: cover;/*keeps image in size when resizing; for all cases?*/

position:absolute;
//top:5px;
overflow:visible;
//height:77%;
height:73%;
 
 width:90%;
 
 //border-color:green;
//border-style:solid;
//border-width:5px;

border-bottom-right-radius:15px;/*round corner of box*/
border-bottom-left-radius:15px;


//box-shadow: 0 0 0 12px #333;
//border-radius: 3px;
	/*probably put inside card frame; nmot currently being used, just put the stuff in card=frame*/
	
	
	
	


	
}


.CardFront{
	//background-image: url(/src/icons/purple-blue-gradient.jpg);
	background-image: url(/src/icons/copia-card.webp);
	
	
}

.CardBack{
	background-image: url(/src/icons/blue-white-gradient.jpg);
	
}

.card-frame{
	
	//font-family: LegionFont; /*Shrikhand*/
position:absolute;
top:0;
overflow:visible;
 height:100%;
 
 //z-index:-2;
 
 width:100%;
 //background-color:white;
 //background-color:black;
 
 

 //background-image: url(/src/icons/pokemon-checkered.jpg);
 //background-image: url(/src/icons/purple-blue-gradient.jpg);
 //background-image: url(/src/icons/orange-gradient.JPG);
 
 //background-image: url(/src/icons/blue-white-gradient.jpg);
 /*will have to make my own gradient diaogna lpattern since I cant find any*/

 //border-color:green;
//border-style:solid;
//border-width:25px;
z-index:-1;


//border-width:15px;
/*makes inner part and outer of card round*/


//background-image: url(/src/icons/mickey-mantle.jpg);
//background-image: url(/src/icons/jordan.jpg);
//background-image: url(/src/icons/mantle.jpg);

	
background-size: cover;
//border-left:-25px;
//border-right:-20px;
//border-left: 30px solid green;
//border-top:-5px;
//border-bottom:-1px;
/*negative values work to make it show up*/
	
	box-shadow: 0 0 0 10px darkvar(--blue);
	border-radius: 2px;
	//border-style:triple;
	/*rounded outer, to look more like a card*/
	
}



/*CARD FRONT */

.ss-stat-div{
	//margin-top:140%;/*this percentage needs to be good with % on height of inner-card-frame*/
	//margin-bottom:100%;
	
	//margin-top:115%; /*changed from 250px to 270px to fit graph so now 115 -> 130?*/
	margin-top:130%;
	
	z-index:-1;/*used to be 2, but changed to -1 to match inenr card frame and automatically be put under*/
	
	
	margin-left:13px;
	
	
	display:flex;/*display flex lets the circles horizontal*/
	
	//margin:auto;
	
}

.stat-square{/*actually a circle, but wont rename yet*/
	
	//z-index:2;
	
	
	
	height:20%;
	width:58%;
	//border-radius: 40%;/*the value is subject to change when resizing other parts of the card*/
	/*^ was 35% bf grouping the circle elements*/
	
	
	border-color:darkblue;
	
	/*i want this to look like a ball/circle consistently. The size keeps changing*/
	//overflow:hidden;
	
	/*^circle effecting elements*/
	
	//font-size:0.80em;
	font-size:0.50em;
	
	bottom: -2%;
	
	position: absolute;
	
	
	//background-color:white;/*green;*/
	

	display:table;

	//margin-bottom:15%;
	
	margin:auto;

	

	
	
}

/*STAT (ss) elements; avg, hi, lo -- circles */
.circle {
        height: 75px;
        width: 75px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
}

.ss-hi{
	/*assuming orientation(from left to right) is Hi-AVG-lo*/
	//margin-left:2%;
	//margin-top:10%;/*for vertical stack; before the stat-square-div ws moved to under playername box*/
	//z-index:2;
	margin-right:15px;
}
.ss-avg{
	
	font-size:0.50em;
	
	//margin-left:30%;
	//margin-left:2%;/*for vertical stack*/
	//z-index:2;
	margin-left:12%;
	
	
	//margin-right:15px;
	
	//border:solid;
	border-width:2px;
	border-color:darkblue;
	
	
	
	
	

}

.ss-lo{
	//margin-left:50%;
	//margin-left:2%;/*for vertical stack*/
	//z-index:2;
	
}

/*STAT (ss) elements; avg, hi, lo -- circles */


/*
.stat-player-name-box:hover {
  z-index:5;
}

.player-name:hover {
  z-index:5;
}

*/


.stat-player-name-box{
	
    letter-spacing: normal;

	
	font-size:0.8rem;
    //line-height: 1.33;
	color: white;
	
	//width:60%;
	width:115%;
	//height:10%;
	
	//height:auto;
	//padding-right:-2px;
	
	/*^imported from name pp*/
	//object-fit:cover;
	
	//left:1px;
	
	z-index:2;

	
	
	
	border-bottom-right-radius:15px;/*round corner of box*/
	//border-bottom-left-radius:15px;
	
	
	position:relative;
}
.player-name{
	//font-size:1.3em;
	 display:flex;
	 height:100%;
	 width: 90%;/*the width of this determines how much of background statbox color is shown*/
	 //width:1rem;
	 //left:10;
	color:var(--yellow);
	 //font-size:0.8em;
	 
	 
	 
	 
	 
	 
	 //min-width:80%;
	 
	   align-items: center;
	justify-content: center;
	 
	 font-family:LegionFont; /*Shrikhand*/
	 
	 //font-family:Trebuchet MS;/*rying to replace Shrikhand with a cleaner, simpler font*/
	 
	 letter-spacing: 1px;/*space between letters for certain spots*/
	 
	 background-color:var(--blue);
	 
	
	//border-bottom-right-radius:15px;/*round corner of box*/
	border-bottom-left-radius:9px;
	//text-align:right;
}


.stk-svg{
	
  //width: 30px;
  //text-align: center;
  text-decoration: none;
  border-radius: 50%;
  margin-left:67%;
  margin-bottom: 20px;
  bottom:-3.5%;
  position:absolute;
  
	
	
}


.league-idol{
	object-fit:contain;
	//z-index:3;
	top:18%;
	left:8%;
	//justify-content:center;
	//margin:auto;
	position:absolute;
	
}


.zoomed-div{
	zoom:67%;
}