


.search-container{
	//background:var(--blue);
	background:var(--container-color); /*purple, formerly lightgreen*/
	
	width:100%;
	height:50px;
	border-radius:10px;
	
	display:flex;
	align-items:center;
	justify-content:center;
}

.search{
	//width: max-content;
	
	--padding: 18px;
	
	width:max-content;
	
	display:flex;
	align-items:center;
	height:34px;
	padding: var(--padding);
	border-radius: 10px;
	
	
	
	background: white;/*darkvar(--blue);/*white;*/
	opacity:0.60;
	//background:red;
}



.search-icon{
	background-image:url(/src/icons/search-icon.png);
	width:20px;
	
	
}

/*.search:focus-within{
	background:#f1f1f1;
	
}*/

.search-input{
	font-size: 16px;
	
	color:grey;
	margin-left: var(--padding);
	outline:none;
	border:none;
	background: transparent;
	
}



.search-input::-webkit-search-cancel-button{
	//-webkit-appearance: none;
}/*clears custom default 'X'/cancel for searchbar*/

.search-input::-webkit-search-cancel-button{
    position:relative;
    right:5px;  

    //-webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius:5px;
    //background: red;
	//color:red;
	//background-image:url(/src/icons/close-x-icon-24.png);
	//object-fit:contain;
	
}


/*ABOVE IS ALL SEARCH ICON FUNCTION, BELOW IS REST OF SEARCH BOX ABOVE CARDS */

.stat-search-n-nav{
	
	height:100%;
	
	background-color:var(--container-color); /*purple, formerly lightgreen*/
}


.stat-nav{
	//margin:auto;
	min-height:40px; /* for mobile, changing this from height to min-height is helps when resizing shrinking*/
	//background:red;
	position:relative;/*keep relative!!!*/
	
	//position:absolute;
	//margin:0;
	width:100%;
	//display:grid;
	
	display:flex;
	align-items:center;
	//justify-content:center;
}

.stat-searchbar{
	
	width:40%;
	min-width:50px;
	min-height:100px;
}

.search-icon{
	//background-image: url(/src/icons/basketball-player-outline.jpg);
	width:20%;
	height:20px;
	background-size: cover;/*keeps image in size when resizing; for all cases?*/
}