stackem-grid{/*for body*/

display:grid;
gap:1rem;
grid-template-columns: repeat(auto-fit, minmax(240px,1fr));

grid-template-areas:
"sidebar container sidebar";



}

l-sidebar{
	grid-area:sidebar;
}

r-sidebar{
	grid-area:sidebar;
	
}

.container{
	grid-area:container;
}

/*put below in different css file*/
.photo-grid{
	display:grid;
	//grid-gap:5px;
	width:90%;
	height:20%;
	//object-fit:contain;
	//justify-content:center;
	flex-wrap:wrap;
	margin:auto;
	grid-template-columns: repeat(auto-fit, minmax(50px,1fr));
	grid-template-areas:
	"auto auto auto auto"
}

/*.available{
	grid-area:available;
}*/

.contact-us-container{/*should have same stuff as about-us container*/
	width:80%;
	justify-content:center;
	margin:auto;
	
}

.cards-container{
	
	
	
	//height:250 px; /*with current card size, 250 is the start of second row of cards; so 250px + 250* players*/
	//height:1450px;/* ~50 + 1400*//*row holds 5*/
	//height:1000px;
	//max-height:auto;
	min-height:1450px;
}

.cards-nba-sizer{
	height:1450px;
	
}


.cards-mlb-sizer{
	height:2650px;
	
}


.grid-fill{
	
	background-color:var(--container-color);
	
}

