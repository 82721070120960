/*navbar css*/

.league-card{
	
	height:100%;
	border-radius:5px;
	//background-image: url(/src/icons/blue-white-gradient.jpg);
	
}

.league-name{
	
	
	//bottom:-4%;
}
.inner-league-name{
	justify-content:center;
	padding-top:7%;
	
}

.league-card:hover{
	
	background-color:white;
	
}