

/* Sticky footer position and size
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 300px;
}
.footer {
  font-family: LegionFont; /*Shrikhand*/
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 300px;
}

/* Taller footer on small screens */
@media (max-width: 567em) {
    body {
      margin-bottom: 700px;
    }
    .footer {
      height: 700px;
    }
}

/* Sticky footer style and color
-------------------------------------------------- */
footer {
  //padding-top: 30px;
  background-color: #292c2f;
  color: #bbb;
}

footer a {
  color: #999;
  text-decoration:none;
}

footer a:hover, footer a:focus {
  color: #aaa;
  //text-decoration:none;
  border-bottom:1px dotted #999;
}

footer .form-control {
    //background-color: #1f2022;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: none;
    resize: none;
    color: #d1d2d2;
    padding: 0.7em 1em;
}

.mt-5{
	font-family: LegionFont; /*Shrikhand*/
	
}



.footer-container{
	
	border-width:0px;
	background-color:var(--container-color); /*purple, formerly lightgreen*/
	justify-content:center;
	
}

.logo-footer{
	//position:absolute;
	//display:grid;
	display:flex;
	margin:auto;
	
	
	//object-fit:contain;
	//height:70%;
	justify-content:center;
	//background-image:url(/src/icons/stackem-with-text-mid-footer.png);
	//background-size:cover;
	
	//height:40%;
}

.logo-footer img{
	//width:100%;
	height:100%;
	max-width:100%;
	//object-fit:cover;
}


/*.fa {//for twitter logo circle
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}*/


.footer-menu-text{
	
	color:black;
	
}

.footer-links{
	display:flex;
	flex-wrap:wrap;
	//width:60%;
	justify-content:center;
	
	height:auto;
	
}




.fa {/*square*/
  padding: 20px;
  font-size: 30px;
  //width: 80px;
  text-align: center;
  text-decoration: none;
  
}


.fa-facebook {
  //background: #3B5998;
  color: white;
}

.fa-reddit{
	//background:white;
	color:red;

}

/* Twitter */
.fa-twitter {
  //background: #55ACEE;
  color: white;
  //object-fit:contain;
}

