.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #0000BD; /*#111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: var(--yellow);/*#818181;*/
  display: block;
  transition: 0.3s;
  
  
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
  text-decoration: underline;
}



/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 36px;
  
  color:var(--yellow);
  
  
  
  margin-left: 50px;
  background:none;
  border:none;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


.hambo {
	
	//display:flex;
	//justify-content:center;
	
	//display:inline-block;
	
	 position: absolute;
	 left: 4%;
	 top:15%;
	
}

.hambo {
  color: #f1f1f1;
  cursor: pointer;
}

.hambo :hover{
	background-color:#BDBDFF;
	cursor: pointer;
}

.closebtn :hover{
	cursor: pointer;
	color: #f1f1f1;
	
}

.header-logo{
	//justify-content:center;
	//display:flex;
	//position:fixed;
	position:absolute;
	//display:inline-block;
	left:45%
	
}
