/*page styles css */


.sports-available-page{
	
	font-family:LegionFont; /*Shrikhand*/
	
}

.sports-available-page .grid{
	display:flex;
	justify-content:center;
	
}

.sports-available-page img{
	margin:15px;
	
	
}

.page-header{
	font-family:LegionFont; 
	letter-spacing:0.4em;
	color:darkvar(--blue);
}

/*contact us*/


.contact-us-page{
	
	
	
	
}
#message{
	//min-width: 40px;
	width:250px;
	min-height:80px;
	//flex:wrap;
	
}

#send-msg{
	font-family:LegionFont;
	
	width:100px
}


.grid-holder{
	//margin-left:34px;
	//margin:20px;
	
	//margin-left:8%;
	margin-right:2%;
	justify-content:center;
	//overflow:clip;
	//align-items: center;
	
}