* {
  box-sizing: border-box;
}

:root{
	--container-color: #6fbcff;/*#6fbcff; /*0384f6;/*#aeb5fe;/**/
	--yellow: #FFFF8F;/*yellow;*/
	--blue: darkblue;
		
}
/*html{
	
	min-height:100%;
	height:100%;
	min-width:100%;
}*/

/*html,body
{
    width: 100%;
    //height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden; 
}*/

@font-face {
    font-family: 'LegionFont'; /*a name to be used later*/
    src: url('BaryaLangPoSaUmagaExtraBoldItalic-p7GJy.ttf') /*URL to font*/
}


hr{
	
	size: 50%;
	color:var(--blue);
	border-top: 1px solid red;
}

br .card-br{
	height:8px;
	width:100%;
}


#colored-div{/*website background */
	//background-color: lightvar(--blue);
	 //background-image: url(/src/icons/orange-gradient.JPG);
	 //background-image: url(/src/icons/image.jpg);
	 //background-image: url(/src/icons/pokemon-checkered.jpg);
	 //background-image: url(/src/icons/blue-white-gradient.jpg);
	 //background-image: url(/src/icons/purple-blue-gradient.jpg);
	//background-size: cover;
	
	
    background-repeat: repeat;
}

/*img{
	
	max-width:100%;
	max-height:100%;
	object-fit:contain;
}*/


body {
	
	//zoom:60%;
	
	
  
	
  //margin: 0;
  
  //background-color: #DBF9FC;
  //background-color: var(--blue);
  min-height:100vh;
  height:100%;
  
  //background-color:grey;
  
  
  
  background-color: var(--container-color);
  
  //background-image: url(/src/icons/stackem-logo-pattern.jpg);
  //background-image: url(/src/icons/stackem-pattern-opacity-35.png);
  
  //background-image: url(/src/icons/background-pattern.jpg);
  
  background-image: url(/src/icons/blue-white-gradient.jpg);
  
  
  //background-repeat: repeat;
  
  scrollbar-color: darkblue var(--container-color);
  
  
  
}

body .container {
  //margin: 1rem;/*this margin effects the space between navbar*/
  
  padding-top: 60px;/*space on top of cards*/
  
  //padding-right:15px;/*these paddings are to allow for card style?*/
  //padding-left:15px;
  
  
  
  //padding: 10px; /* 10px padding */
  //border: 2px solid red;
  //background-clip: content-box; /* <---- */
  box-shadow: inset 0 0 0 28px var(--container-color); /*purple, formerly lightgreen*/ 
  //border-style:solid;
  //border-left:3px;
  //border-right:3px;
  
  
  
  
  text-align: center;
  //min-height:640px;
  //min-height:1200px;
  
  //min-width:768px;
  
  //border-style:solid;
  
  position:relative;
  
  height:80%;
  
  //overflow: visible;
  //overflow: initial;
  //overflow:auto;/*AUTO!! this is what works!!!, to resize div as cards are generated*/
  
  //min-height:80vh;/*it is working rn, at least on dual monitor*/
  //min-height:100vh;
  //height:600px;
  //min-height:600px;
  
  //background-image: url(/src/icons/pokemon-checkered.jpg);

  //background-color:lightvar(--blue);
  //background-color:#F0FFFF;
  background-color:var(--container-color); /*white;/*white background*//*the background behind react-flip-container and above*/
  
  
  /*need to find a way to get the checkered just in card box, not full container, will probably need solution not in this class */
  /* TAKEN FROM PRIZEPICKS
    min-width: 768px;
    height: 100%;
    overflow: visible;
    overflow: initial;
    min-height: 70vh;
  
  */
}

/*@media (max-width: 768px) {
.container {
grid-template-columns: repeat(2, 1fr);
}
}
*/




.sports-available-content{
	//justify-content:center;
	//margin:auto;
}



.nav {
	
	//justify-content:center;
	//margin:auto;
	//display: flex;
	display:grid;
	grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
	//height:80%;
	
	//top:0.3rem;
	
	
	background-color:var(--container-color); /*purple, formerly lightgreen*/
	
	
	
  //background-color: grey; /*next to cards*/
  color: white;/*just seems to affect text color*/
  
  //justify-content: space-between;
  justify-content:center; /*KEEP center, not space-beween*/
  //align-items: stretch;
  gap: 1.3rem;
  
  
  //padding: 0 1rem;
  //padding:1rem;
  
  //position:absolute;
  //top:0px;/*top 0px should be for the header only*/
  //position:fixed;
  position:relative;/*relative is needed, not fixed or absolute; this is for the sections to fall under and not just overlap/obstructu each other*/
  //position:sticky;
  width:100%;
  
  
  //height:8em;
  height:5.5em;
  
  //margin:auto;
  //margin:0;
  
  //z-index:5;
  
  //overflow-x:scroll;
  overflow-x:hidden;
  overflow-y:hidden;
  //border-style:solid;
  
}

hr{
	background-color:var(--container-color); /*purple, formerly lightgreen*/
	//border-color:var(--container-color); /*purple, formerly lightgreen*/
}


Link{
	//margin:0;
	
}

.header{
	background-color: var(--blue);
	margin:0;
	
	//height:120px;
	height:60px;
	
	//position:relative;
	//position:absolute;
	
	//display: flex;/*also seems to cntribute to no space between divs*/
	
	
	font-family:LegionFont; /*Shrikhand*/
	
	
	padding:0px;
	
	width:100%;
	top:0px;
	////width:100%;
	position:fixed;
	z-index:5;
	
	/*maybe column format may be better for holding logo and words/titles?*/
	/*STACKED SPORTS should be a svg/image */
	justify-content: center;
	/*SEARCH*/
}

.footer{
	/*issue here, too long*/
	background-color: grey;
  color: var(--blue);/*just seems to affect text and bullet color*/
  display: flex;
  //display:block;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  //padding: 0 1rem;
  
  position:relative;
  //position:fixed;
  //position:absolute;
  
  //bottom:0px; /*removed and trying to replace with just 0*/
  bottom: 0;
  
  min-width:80%;
  height:10em;
  //margin:0;
  
  //z-index:5;
	
	/*NEED WAY TO KEEP FOOTER AT BOTTOM OF */
	height:300px;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

table{
	
	margin: 0 auto;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}