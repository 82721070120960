/*td{
	border: 1px solid black;
	
}*/

/*body{
	//margin:0;
	min-height:100vh;
}*/








/*move this to header editing file, reorganize stuff */















/*             */


/*th{
	display: table-cell;
    vertical-align: inherit;
	//padding-right50%; //thats why stuff was so close
	
	
}

td {
    display: table-cell;
    vertical-align: inherit;
	//padding-right:1em;
	
	padding:2px 6px 2px 6px;//for space between columns in table
}*/
td {
    display: table-cell;
}
th{
	display: table-cell;
}







/*back of card*/

.card-back-player-info{/*will contain extra info, bulleted list probably*/ /*for now, just sttic player info so don't have to reach into some other database for it*/
	/*needs margin and positioning*/
	//text-align:left;
	max-width:100%;
	max-height: 100%;
	position:absolute;/*this keeps card back from being overgrown*/
	font-size:0.75rem;
	display:block;
	
}

.player-position{
	display:flex;
	font-size:0.91em;
	font-weight:bold;
	width: 20%;
	
	margin-top:25%;
	//height:100%;
}


.player-season-stats{
	
	display:block;
	width:100%;
	//position:absolute;
	
	
}

.player-bio-element{
	
	//display:inline-block;
	margin:3px;
	font-size:0.6rem;
}













.front-card, .back-card{
	
	position:absolute;
	//width:100%;
	//height:50%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background:skyvar(--blue);
	
	
}

.front-card{
	z-index:2;
	transform: rotateY(0deg);
	transform-style:preserve-3d;
	
}
.back-card{
	
	transform: rotateY(180deg);
	background-color: light-var(--blue);
}

/*.front-card{
	
}*/






/*GRAPH STUFF*/


/*.table-and-graph{
	
	width:85%; 
	//width:0;
	
	height:60%;
	//background-color:green;
	margin:auto;
	display:flex-box;
	//overflow:visible;
	//overflow:hidden;

	font-family: LegionFont; //Shrikhand
	border-style:solid;
	border-color:green;
	
	
}*//*was deleted, may not need to be anymore*/



/*END of GRAPH stuff*/

.front-stat-title{
	position:absolute;
	//bottom:17%;
	bottom:15%;
	//justify-content:center;
	width:80%;
	margin:auto;
	
	
}









.logo-link-container{
	/*this contains logo img and needs to be formatted or formats removed from default to have 
	logo appear like when not in container (ie. just in header)*/
	position:absolute;
	
	
}

.logo-box{
	background-image: url(/src/icons/youtube-logo.png);/*background image works at least, so dont worry about svg specific parent div or whatever*/	
	
	
	
	background-size: cover;
	//object-fit:contain;/*might be useful, idk*/
	/*curent solution to size prolem, must define size in tag?*/
	/*had to shrink image file size to avoid too big problems*/
	/*quick solution, have border and make width large-->make broder translucent?nope doesnt work has reflection*/
	//border: 10px solid transparent;/*not working well*/
	/*^...i guess jsut make border same color as background*/
	
	
	width:120px;
	height:100%;
	border-style:solid; 
	border-width:20px;
	//border-color:var(--blue);
	
	//position:absolute;
	//margin:auto;
}

.h-title{
	
	position:absolute;
	//padding:20px;
	bottom:0;
	
}

/*.ss-logo-letters{
	//margin:0;
	//margin-bottom:20px;
	//display:flex;
	//justify-content:center;
	
	font-family:LegionFont; /*Shrikhand
	bottom:0;
	border-style:solid; 
	border-width:6px;
	
	position:static;
	margin:auto;
	
}
*/

















/*ignore this stuff, at least for now*/
.back-face{
	
	//font-family: LegionFont; /*Shrikhand*/
	
}

.front-face{
	
	
	
}

.corner-circle{
	//width:30px;/*corner-circle is what will hold the SS logo somewhere in the card*/
	padding:15px;
	
	//margin-bottom:15px;
}


/*PAGE-FRAME and stucture: needs its own css file!!!!!!!!!*/


.stackedsports{/*header? or at least the all encompassing div of the site(for backgroudn color, etc)*/

   background-image: url(/src/icons/pokemon-checkered.jpg);/*only does strip under navbar, may be more effective to do for entire page*/
	
}

.flip-card-container{
	
	
	
	
	
	font-family: LegionFont; /*Shrikhand*/
	
	//background-color:lightvar(--blue);
	
	min-width:300px;
	//min-height:300px;
	
	//height:100%; /*this gets rid of space between card rows?*/ /*change height of parent div so the height dimensions don't have to be defined*/
	
	
	//height:1450px; /*not sure how to stop the large spaces under the cards during/when searching single player name; height as 100% is better, so need to make the div directly outside have the static height*/
	width:100%;
	
	//overflow:auto;
	
	overflow:clip;
	
	
	
	//border-style:solid;	
	//border-width:2px;
	
	//justify-content: center;
	//display:contents;/*display flex makes rotate in place, but now there is overflow horizontally*/
	//display:table-row-group;
	
	//display:block flex;
	
	
	
	
	/*like on cat thing ; decent enough*/
	display:flex;
	flex-flow:wrap;
	justify-content:center;
	
	//display:inline;
	//margin-left:8%;
	//margin-right:8%;
	//overflow:auto;
	
}

.react-card-flip{
	//perspective:none;
	//color:var(--blue);
	//transition: transform 500ms ease-in-out;
	//z-index:auto;
	//height: 100px;
	//height:3em;
	
}


.CardBack{
	//display: flex;
  flex-direction: column;
  align-content: stretch;
  //font-family: LegionFont; /*Shrikhand*/
	//height:100px;
	//animation:none;
}

.CardFront{
	//transform: rotateY(-180deg);
	//transition: transform 500ms ease-in-out;
	animation:none;
	//height:100px;
}






.league-nav{/*hold list of divs*/
	
	grid-gap: 8px;
    box-sizing: border-box;
    align-items: center;
    font-size: 0.85rem;
    //-webkit-user-select: none;
    //-moz-user-select: none;
    user-select: none;
    display: flex;
    //overflow-x: scroll;/*but there is overflow, so make space/margin at some point*/
	width:100%;
	
	
	font-family: LegionFont; /*Shrikhand*/
	
}



.inner-league-name{/*this has saved the card and fixed the problem, just put it around the league name text*/
	display:flex;
	//margin: 2px;
	margin:auto;
	font-family:LegionFont; /*Shrikhand*/
	letter-spacing: .2rem;
	font-size: 0.58em;
	
}
.card-link{
	position:relative;
	justify-content:center;
	margin:auto;
	display:flex;
	
}

.league-link{/*styling of Link to remove padding sheesh*/
	
	/*display:inline-block;
	
	
	padding:0;
	margin:0;
	//bottom:0px;
	//border-style:solid;
	position:absolute;
	//bottom: 0;
	//width:100%;
	max-width:50px;
	height:100%;
	
	object-fit:contain;
	*/
	
	//bottom:0;
	//position:relative;
	
	
	//background-size: cover;
	//object-fit:fill;
}


.about-page{
	
	//width:100%;
	//height:100%;
	
	
	
}

.info-page{
	display:grid;
	width:100%;
	min-height:100%;
	border-style:solid;
	border-color:green;
	//position:relative;
	height:500px;
}

.about-us-container{
	margin:auto;
	padding:10px;
	padding-top:20px;
	//font-family: LegionFont; /*Shrikhand*/
	
}

.about-us-text-container{
	font-size:.9rem;
	width:80%;
	margin:auto;
}

.privacy-policy-container{
	
	font-size:.9rem;
	width:80%;
	margin:auto;
	
}

.privacy-policy-container h1,h2,h3{
	
	font-family:LegionFont;
	
}

.privacy-policy-header{
	font-family:LegionFont; /*Shrikhand*/
}

.about-us-header{
	font-family:LegionFont; /*Shrikhand*/
}

.contact-header{
	font-family:LegionFont; /*Shrikhand*/
}

.page-image-container img{
	//height:100%;
	max-width:80%;

}

.page-image-text-container{/*the image goes over the lightblue background div when zooming in*/
	
	//height:100%;
	
	position:relative;
	//position:absolute;
	
	/*max-width: fit-content;
	  //margin-left: auto;
	 // margin-right: auto;*/
  
  
	background-image:url(/src/icons/sports-cards-collection.jpg);
	background-size:cover;
	//height:auto;
	//display:grid;
	/*object-fit:contain or *fill* or cover*/
	padding:0;
	margin:auto;
	top:0;
	object-fit:contain;
	max-width:90%;
	//height:40px;
	min-height:30%;
	//font-family: LegionFont; /*Shrikhand*/
	
	
	
	//border-style:solid;
	//border-color:var(--blue);
	
}

.league-icon-container{
	max-width:90%;
	top:0;
	max-height:60%;
	position:absolute;
	
}

.league-icon-container img{
	object-fit:cover;
	
}

.league-icon{/*goes on top of league name*/
	
	//height:100%;
	background-size:cover;
	//height:auto;
	//display:grid;
	/*object-fit:contain or *fill* or cover*/
	//padding:0;
	//margin:0;
	//margin:auto;
	//top:0;
	//max-width:100%;
	//max-height:100%;
	
	width:100%;
	height:100%;
	
	//border-style:solid;
	
}

a{
	
	position:relative;
	
}Link{
	
	position:relative;
	
}

.league-name{/*must have matching color as border(if there is border) of league card*/


	
	//background-color:orange;
	background-color:var(--blue);
	//color:black;
	
	color:var(--yellow);
	
	width:100%;
	bottom:0;
	//margin-left:px;
	//height:25%;
	height:20%;
	position:absolute;
	padding:0px;
	//margin:2px;
	//margin-left:3px;
	
	
	/*display:flex;
	z-index: 2;
	height:25%;
	min-width:100%;
	//width:100%;
	//width:100px;
	
	//width:100%;
	//justify-content:center;
	//text-align: center;
	
	//align-items:center;
	
	
	font-family: LegionFont; /*Shrikhand*/
	font-size:0.95rem;
	//font-size:1em;
	
	//margin:0;
	bottom:0; /*after all this hassle, learned that parent must be relative before the child is absolute*/
	/*position:absolute;
	//position:relative;
	margin:3px;
	//padding:0;*/
	
	
	
}	

.league-icon-container img{
	
	width:90%;
	//height:60%;
}


.league-card{
	//object-fit:contain;/*might be useful, idk*/
	//object-fit:fill;
	//background-size: cover;
	//position:relative;
	overflow:clip;
	display:flex;
	//display:table-cell;
	
	justify-content:center;
	align-items: center;
    
	position:relative; /*this is parent that needs to be relative; the league-name div is absolute for stacking*/
	//position:absolute;
	
	 border-radius: 2px;
   
    background-color: #ffffec;/*lightblue*/
	
	/*border and strip for league name*/

	//height:80%;
	//height:78%;
	//height:98%;
		//width:5%;
	    min-width: 55px;
		
        //width: 40px;
        min-height: 72px;
        //height: 72px;

    //max-width: 60px;
	//width:55px;
	//width:65px; /*commented out on 5/14*/
	//width:75px;
	
	
	border-width:6px;
	//border-color:red;
	border-color:var(--blue);
	//border-top:12px;
	//border-top-color:var(--blue);
	border-style:solid;
	margin:0;
	padding:0;
	
	
	

	
}









.stat-button{
	
	border-radius:6px;
	//min-width:70px;
	//min-width:12%;
	font-family: LegionFont; /*Shrikhand*/
	font-size:0.74rem;
	color:white;
	background-color:var(--blue);
	//border-color:#001acd;
	border-color:var(--blue);
	
	
}



.stat-nav{/*contains stat gallery*/

	padding: 4px;
	
	
	display: flex;
	//display:1;
    flex-wrap: flex-shrink;
    flex-wrap: wrap;
	
	justify-content:center;
    //overflow-x: scroll;
    gap: 10px;
	background:var(--container-color);
	border-radius:6px;
	//min-height:100%;
	//min-width:100%;
	//height:80px;
	
	height:auto;/*auto height allows div/nav to resize as contents fill*/
	
	
	margin-bottom: 5px ;/*for space at bottom between divs*/
}










/*at some point may have to adjust code to not look exactly like prizepicks in only copy paste parts, but maybe not even at all*/








/* I dont think any of this was being used in the most worked version
.flipper{
	transition:0.8s;
	transform-style:preserve-3d;
	position:relative;
	
}

.flip-container.hover .flipper {
    transform: rotateY(180deg);
}


*/