/*recharts elements css */
.recharts-surface{
	
	/*cant do viewbox in here, but find appropirate dimensions to do it viewbox = 5;*/
	/*add y-axis line to recharts */
	
	//width:70%;/*resize graph / recharts */
	width:70%;
	margin:auto;
	height:65%;
	
	//margin-right:61px;/*bad practice to hardcode these values, especially when resizing/mobile*/
	
	margin-right:25%;
	//margin-bottom:40%;
	//min-height:8em;
	//min-width:8em;
	z-index:3;
	
	
}

.recharts-wrapper{
	
	/*might not need anything*/
	//width:100%;
	//height:80%;
	
}

.recharts-cartesian-axis-ticks {    
  /* for tick sizes and fonts on axes*/
    font-size: 0.80rem;
    font-family: Impact, fantasy;
}

ResponsiveContainer{
	
	background-color:var(--blue);
	z-index:3;
	//width:100%;
	width:80%;
	
}