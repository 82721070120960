

.graph-container{
	
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	
}



fieldset {
    text-align: center;
    font-size: 25px;
	border-color:black;
	border-style:solid;
	border-width:20px;
}

legend {
    font-size: 15px; 
}



.table-and-graph{/*border of recharts graph*/
	//border-style:solid;
	//width:80%;
	object-fit:cover;
	
	
}


table{
	border-collapse: collapse;
	//border:solid black 1px;
    //border-radius:10px;
}


.table-container{
	
	
	/*rounded corner*/
	//border-style:solid;
	//background-color:grey;
	width:100%;/*kepth the color of thead aligned with chart*/
	//max-width:100%;
	//margin:auto;
	z-index:3;
	
	
	bottom:0;
	//font-family: Impact, fantasy;/*only effects before table break*/
	font-family: LegionFont; /*Shrikhand*/
	//position:absolute;
}

.table-break{
	
	background-color:var(--blue);
	width:565%;
	height:10px;
	margin-right:auto;
	
	
}




/*.stat-table th{ /*first child css for width might be better, but more costly so doing a class instead
	border-right:solid;
	border-style:solid;
	border-color:var(--blue);
	
	border-right: solid 1px black; 
  border-left: solid 1px black;
	//justify-content:center;
	
}*/

.stat-table td{
	
	color:black;
	border-right:dotted 1px black;
	justify-content:center;
}

.stat-table thead:first-child {
  width: 8em;
  //min-width: 6em;/*changed from 6em to 4em, after LOL issues*/
  max-width: 14em;
  //word-break: break-all;/*idk what this does, but keep it*/
  border-right:dotted 1px black;
  //text-align: left;
  left:2px;
  
}

.stat-table tbody:first-child {
  width: 8em;
  background-color:var(--blue);
  //min-width: 6em;/*changed from 6em to 4em, after LOL issues*/
  max-width: 14em;
  //word-break: break-all;/*idk what this does, but keep it*/
  //border-right:none 1px black;
  //text-align: left;
  left:2px;
  
}


.stat-table tr:first-child {
	font-size:0.9em;
  width: 10em;
  //background-color:var(--blue);
  min-width: 10%;/*changed from 6em to 4em, after LOL issues*/
  //max-width: 14em;
  //word-break: break-all;/*idk what this does, but keep it*/
  //border-right:dotted 1px black;
  //text-align: left;
  left:2px;
  
}


.stat-table th{
	border-right:solid;
	border-style:solid;
	border-color:var(--blue);
	color:white;
	background-color:var(--blue);
	
	border-right: solid 1px black; 
  border-left: solid 1px black;
	//justify-content:center;
	
}

.td-year{
	
	color:red;
	
}

.stat-table{
	font-size:.6rem;
	background-color:lightgrey;
	width:100%;
	//border-collapse:collapse;
}
.graph{
	
	//position:absolute;
	
	
	position:relative;
	//margin:0;
	//width:300px;
	//margin:20px;
	//object-fit:contain;
	//display:flex-box;
	
	//background-color:white;
	
	
	
	//min-height:12em;
	min-width:8em;
	
	
	//margin:auto;
	//margin:9px;
	
	//width:80%;
	//margin:auto;
	
    //border-style:solid;
    //border-width:1px;
	//width:50%;
	/*height:250px;
	width:250px;
	object-fit:contain;
    
    /*height:10em;
    padding:10px 10px;*/
	//padding:1px;
	//overflow:hidden;
	//position:relative;
	//float:left;
	//flex-wrap:nowrap;
	/*
	margin-left:auto;
	margin-bottom:auto;
	margin-right:auto;
	margin-top:auto;*/
	
    //flex-direction:left;
    
	z-index: 3;/*as high as possible to prevent being hidden*/
}







